<template>
  <el-form :model="configForm" ref="configForm" label-width="150px" label-position="left">
    <div v-for="(item, index) in configDataForm" :key="index">
      <el-form-item
        :label="item.label"
        v-if="item.isInput != false"
        :rules="item.rules"
        :prop="item.attr"
      >
        <el-input
          v-if="item.type == 'textarea'"
          type="textarea"
          v-model="configForm[item.attr]"
          :placeholder="item.placeholder"
        ></el-input>
        <el-input
          v-else
          v-model="configForm[item.attr]"
          :placeholder="item.placeholder"
        ></el-input>
      </el-form-item>
    </div>
    <el-form-item>
      <el-button type="primary" @click="submitForm('configForm')">保存</el-button>
      <el-button @click="resetForm('configForm')">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: "saveTaideConfig",
  props: {
    configId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      configForm: {
        Id: 0,
        ConfigName: "", 
        AppID: "", 
        AppKey: "", 
        AppSecret: "",
        RemarkInfo: "",
      },
      configDataForm: [
        {
          label: "Id",
          attr: "Id",
          isInput: false,
        },
        {
          label: "名称",
          attr: "ConfigName",
          placeholder: "请输入配置名称",
          rules: [
            {
              required: true,
              trigger: "blur",
              message: "请输入配置名称",
            },
            {
              min: 0,
              max: 50,
              message: "长度不大于50个字符",
              trigger: "blur",
            },
          ],
        },
        {
          label: "代码(SCHOOLID)",
          attr: "ConfigCode",
          placeholder: "请输入配置代码(SCHOOLID),向泰德对接人获取!",
          rules: [
            {
              required: true,
              trigger: "blur",
              message: "请输入配置代码(SCHOOLID),向泰德对接人获取!",
            },
            {
              min: 0,
              max: 100,
              message: "长度不大于100个字符",
              trigger: "blur",
            },
          ],
        },
        {
          label: "Key(SCHOOLKEY)",
          attr: "ConfigKey",
          placeholder: "请输入配置Key(SCHOOLKEY),向泰德对接人获取!",
          rules: [
            {
              required: true,
              trigger: "blur",
              message: "请输入配置Key(SCHOOLKEY),向泰德对接人获取!",
            },
            {
              min: 0,
              max: 100,
              message: "长度不大于100个字符",
              trigger: "blur",
            },
          ],
        },
        {
          label: "备注",
          attr: "RemarkInfo",
          placeholder: "请输入备注（长度不大于100个字符）",
          rules: [
            {
              min: 0,
              max: 100,
              message: "长度不大于100个字符",
              trigger: "blur",
            },
          ],
          type: "textarea",
        },
      ],
    };
  },
  created() {
    var that = this;
    if (that.$props.configId != 0) {
      that.$API.TaideConfig.QueryById.get(that.$props.configId).then((res) => {
        that.configForm=res;
      });
    }
  },
  methods: {
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.$API.TaideConfig.AddOrUpdate.post(that.configForm).then((res) => {
            if (res.Success) {
              that.$message.success(res.Message);
              that.$emit("operationSuccess");
            }else{
                that.$message.error(res.Message);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style></style>
